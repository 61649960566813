<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="px-5 py-4">
              <b-card-body>
                <!-- <h1 class="mb-4">Login</h1> -->
                <img class="m-auto d-block" src="img/brand/logo.png" width="160" height="40" alt="Logo">        
                <b-row class="mt-5">
                    <ResponseAlert ref="response" />
                </b-row>
                <form @submit.prevent="login" class="needs-validation" novalidate>
                  <div class="form-group" :class="{'has-error': errors.has('username') }">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="icon-user"></i></div>
                      </div>
                      <input v-model="models.email" 
                              v-validate="'required'" 
                              name="email" 
                              class="form-control" 
                              type="text" 
                              placeholder="Email">
                    </div>
                    <div class="help-block" v-if="errors.has('email')">
                      <p class="text-danger">{{ errors.first('email') }}</p>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('password') }">
                    <div class="input-group position-relative">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="icon-lock"></i></div>
                      </div>
                      <input v-model="models.password" 
                              v-validate="'required'" 
                              name="password" 
                              class="form-control" 
                              :type="vars.type.password" 
                              placeholder="Password">
                      <div @click.prevent="togglePassword('password')" class="position-absolute" style="right: 13px; bottom: 13px; cursor: pointer; z-index: 99">
                        <i class="fa fa-eye"></i>
                      </div>
                    </div>
                    <div class="help-block" v-if="errors.has('password')">
                      <p class="text-danger">{{ errors.first('password') }}</p>
                    </div>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <b-button  type="submit" variant="primary" class="px-4 btn-block">Login</b-button>
                    </b-col>
                    <b-col cols="12" class="text-center">
                      <b-button to="forgot-password" variant="link" class="">Forgot password?</b-button>
                    </b-col>
                  </b-row>
                </form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ResponseAlert from '@/components/response_alert';
import api from '@/utils/api.js';

export default {
  name: 'Login',
  components: {
    ResponseAlert,
  },
  data() {
    return {
      models : {
        email: '',
        password: '',
        role: 'admin'
      },
      vars: {
        type: {
          password: 'password'
        }
      }
    }
  },
  methods : {
    login(){
      const _ = this;
      _.$validator.validate().then(valid => {
        if (valid) {
          _.$axios.post(api.login, _.models)
          .then(resp => {
            this.$showToast('Success', 'Success login')
            _.$store.dispatch('login',resp.data.data)
          })
          .catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
        }
      });
    },
    togglePassword(type) {
      const _ = this
      _.vars.type[type] = _.vars.type[type] === 'password' ? 'text' : 'password'
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    if(window.location.hostname !== 'localhost') {
      this.models.role = window.location.origin === process.env.VUE_APP_URL_ADMIN ? 'admin' : 'contributor'
    }
  }
}
</script>